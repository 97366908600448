import React from 'react';
// import waitingLoad from '../assets/asusailogo.png';

const Loading = () => {
  return (
    <div className='flex justify-center items-center h-screen bg-black'>
      {/* <img className='h-auto w-auto' src={waitingLoad} alt="Loading" /> */}
    </div>
  );
};

export default Loading;
