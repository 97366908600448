import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import LogoByd from "../../assets/byd.png";
import Swal from "sweetalert2";

const questions = [
  {
    question: "Apa fitur utama dari desain Blade Battery?",
    options: [
      { text: "Mudah diganti", isCorrect: false },
      {
        text: "Meningkatkan keamanan",
        isCorrect: true,
      },
    ],
    feedback:
      "Desain Blade Battery yang meningkatkan keamanan dengan menurunkan risiko kebakaran, membuatnya lebih andal untuk digunakan dalam kendaraan listrik.",
  },
  {
    question: "Apa yang membuat Blade Battery istimewa?",
    options: [
      { text: "Harganya murah", isCorrect: false },
      {
        text: "Keamanan dan efisiensi tinggi",
        isCorrect: true,
      },
    ],
    feedback:
      "Blade Battery dikenal karena keamanan dan efisiensinya yang tinggi, memberikan performa yang andal dan aman.",
  },
  {
    question: "Mengapa Blade Battery penting bagi industri kendaraan listrik?",
    options: [
      { text: "Karena lebih mudah diproduksi", isCorrect: false },
      {
        text: "Karena meningkatkan keamanan dan efisiensi",
        isCorrect: true,
      },
    ],
    feedback:
      "Blade Battery meningkatkan keamanan dan efisiensi kendaraan listrik, sehingga memungkinkan ev menempuh jarak lebih jauh sekaligus meningkatkan keamanan EV",
  },
  {
    question: "Apa material utama yang digunakan dalam Blade Battery?",
    options: [
      { text: "Lithium Iron Phosphate (LFP)", isCorrect: true },
      {
        text: "Lithium Cobalt Oxide",
        isCorrect: false,
      },
    ],
    feedback:
      "Blade Battery menggunakan Lithium Iron Phosphate (LFP) yang lebih aman dan stabil dibandingkan Lithium Cobalt Oxide yang relatif lebih mudah terbakar. Lithium Iron juga lebih ramah lingkungan karena lebih tidak beracun dibanding cobalt, dan lebih mudah di daur ulang.",
  },
  {
    question: "Berapa persen ruang yang dihemat oleh Blade Battery?",
    options: [
      { text: "20%", isCorrect: false },
      {
        text: "50%",
        isCorrect: true,
      },
    ],
    feedback:
      "Blade Battery yang lebih padat, memungkinkan untuk dapat menyimpan kapasitas daya dalam medium yang 50% lebih ringkas, memberikan luas kabin yang lebih lapang.",
  },
];

function Question1() {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(null);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(null);
  const [score, setScore] = useState(0);
  const navigate = useNavigate();

  const currentQuestion = questions[currentQuestionIndex];

  const handleAnswerClick = (index, isCorrect) => {
    setSelectedAnswerIndex(index);
    setIsAnswerCorrect(isCorrect);

    if (isCorrect) {
      setScore(score + 20);
    }

    Swal.fire({
      title: isCorrect ? "Correct!" : "Wrong!",
      text: currentQuestion.feedback,
      icon: isCorrect ? "success" : "error",
      confirmButtonText: "Next",
    }).then(() => {
      setSelectedAnswerIndex(null);
      if (currentQuestionIndex < questions.length - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      } else {
        Swal.fire({
          title: "Quiz Finished!",
          text: `You have completed the quiz with a score of ${
            score + (isCorrect ? 20 : 0)
          }/${questions.length * 2}0.`,
          icon: "info",
          confirmButtonText: "Finish",
        }).then(() => {
          navigate("/PlatformHome", { replace: true });
        });
      }
    });
  };

  return (
    <div className="h-screen w-screen flex justify-center items-center bg-gradient-to-r from-blue-400 to-blue-500">
      <div className="flex flex-col items-center text-xl text-center p-5 bg-slate-100 rounded-lg bg-opacity-30 space-y-5 max-w-lg w-full mx-4">
        <img className="w-24 h-auto" src={LogoByd} alt="Logo BYD" />
        <p className="uppercase font-bold">{currentQuestion.question}</p>
        {currentQuestion.options.map((option, index) => (
          <button
            key={index}
            className={`p-4 w-full rounded-lg font-bold text-white ${
              selectedAnswerIndex === index
                ? isAnswerCorrect
                  ? "bg-green-500"
                  : "bg-red-500"
                : "bg-blue-500"
            }`}
            onClick={() => handleAnswerClick(index, option.isCorrect)}
            disabled={selectedAnswerIndex !== null}
          >
            {option.text}
          </button>
        ))}
      </div>
    </div>
  );
}

export default Question1;
