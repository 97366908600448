import React from "react";
import { useNavigate } from "react-router-dom";
import LogoByd from "../assets/byd.png";

function HomePage() {
  const navigate = useNavigate();

  const goToPlatformHome = () => {
    navigate("/PlatformHome", { replace: true });
  };

  const goToBladeHome = () => {
    navigate("/BladeHome", { replace: true });
  };

  return (
    <div className="h-screen w-screen flex flex-col justify-center items-center space-y-16">
      <img className="w-fit h-20 px-5" src={LogoByd} alt="" />
      <div className="flex flex-row space-x-5">
        <button
          onClick={goToPlatformHome}
          className="p-4 rounded-lg bg-blue-500 font-bold text-white animate-pulse"
        >
          E-Platform
        </button>
        <button
          onClick={goToBladeHome}
          className="p-4 rounded-lg bg-blue-500 font-bold text-white animate-pulse"
        >
          Blade Battery
        </button>
      </div>
    </div>
  );
}

export default HomePage;
