import React from "react";
import LogoByd from "../assets/byd.png";
import { useNavigate } from "react-router-dom";

function PlatformHome() {
  const navigate = useNavigate();

  const goToQuestion1 = () => {
    navigate("/PlatformHome/question1", { replace: true });
  };

  return (
    <div className="h-screen w-screen p-4 flex flex-col justify-center items-center space-y-16">
      <img className="w-fit h-20" src={LogoByd} alt="" />
      <button
        onClick={goToQuestion1}
        className="py-4 px-16 rounded-lg bg-blue-500 font-bold text-white animate-pulse"
      >
        START
      </button>
    </div>
  );
}

export default PlatformHome;
