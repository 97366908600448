import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import LogoByd from "../../assets/byd.png";
import Swal from "sweetalert2";

const questions = [
  {
    question:
      "Apa yang membuat E-Platform 3.0 lebih stabil dalam kondisi berkendara ekstrem?",
    options: [
      { text: "Sistem suspensi yang lebih stabil", isCorrect: false },
      {
        text: "Desain lantai datar dan struktur rangka yang kuat",
        isCorrect: true,
      },
    ],
    feedback:
      "Desain lantai yang datar dan struktur rangka yang kuat dan solid membantu mendistribusikan berat kendaraan secara merata, sehingga meningkatkan stabilitas saat berkendara.",
  },
  {
    question: "Bagaimana E-Platform 3.0 meningkatkan keamanan kendaraan?",
    options: [
      { text: "Dengan mengurangi jumlah komponen", isCorrect: false },
      {
        text: "Dengan integrasi baterai ke dalam struktur bodi mobil",
        isCorrect: true,
      },
    ],
    feedback:
      "Integrasi baterai ke dalam struktur bodi mobil membuat kendaraan lebih kuat dan stabil, sehingga meningkatkan keamanan.",
  },
  {
    question: "Apa keuntungan dari sistem pendingin langsung pada E-Platform?",
    options: [
      { text: "Meningkatkan efisiensi termal hingga 20%", isCorrect: true },
      {
        text: "Mengurangi berat kendaraan",
        isCorrect: false,
      },
    ],
    feedback:
      "Sistem pendingin langsung meningkatkan efisiensi termal hingga 20%, yang berarti kendaraan lebih efisien dalam penggunaan energi.",
  },
  {
    question:
      "Bagaimana penempatan Blade Battery pada E-Platform mempengaruhi stabilitas mobil?",
    options: [
      { text: "Membuat mobil lebih berat", isCorrect: false },
      {
        text: "Membuat mobil lebih stabil",
        isCorrect: true,
      },
    ],
    feedback:
      "Penempatan baterai di posisi rendah menurunkan pusat gravitasi kendaraan, yang membuat mobil lebih stabil saat berkendara.",
  },
  {
    question:
      "Apa manfaat dari penempatan mesin pada E-Platform untuk interior kendaraan?",
    options: [
      { text: "Mengurangi ruang interior", isCorrect: false },
      {
        text: "Meningkatkan interior yang lebih luas untuk penumpang dan bagasi",
        isCorrect: true,
      },
    ],
    feedback:
      "Penempatan mesin yang efisien memberikan lebih banyak ruang bagi penumpang dan bagasi, meningkatkan kenyamanan.",
  },
];

function Question1() {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(null);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(null);
  const [score, setScore] = useState(0);
  const navigate = useNavigate();

  const currentQuestion = questions[currentQuestionIndex];

  const handleAnswerClick = (index, isCorrect) => {
    setSelectedAnswerIndex(index);
    setIsAnswerCorrect(isCorrect);

    if (isCorrect) {
      setScore(score + 20);
    }

    Swal.fire({
      title: isCorrect ? "Correct!" : "Wrong!",
      text: currentQuestion.feedback,
      icon: isCorrect ? "success" : "error",
      confirmButtonText: "Next",
    }).then(() => {
      setSelectedAnswerIndex(null);
      if (currentQuestionIndex < questions.length - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      } else {
        Swal.fire({
          title: "Quiz Finished!",
          text: `You have completed the quiz with a score of ${
            score + (isCorrect ? 20 : 0)
          }/${questions.length*2}0.`,
          icon: "info",
          confirmButtonText: "Finish",
        }).then(() => {
          navigate("/PlatformHome", { replace: true });
        });
      }
    });
  };

  return (
    <div className="h-screen w-screen flex justify-center items-center bg-gradient-to-r from-blue-400 to-blue-500">
      <div className="flex flex-col items-center text-xl text-center p-5 bg-slate-100 rounded-lg bg-opacity-30 space-y-5 max-w-lg w-full mx-4">
        <img className="w-24 h-auto" src={LogoByd} alt="Logo BYD" />
        <p className="uppercase font-bold">{currentQuestion.question}</p>
        {currentQuestion.options.map((option, index) => (
          <button
            key={index}
            className={`p-4 w-full rounded-lg font-bold text-white ${
              selectedAnswerIndex === index
                ? isAnswerCorrect
                  ? "bg-green-500"
                  : "bg-red-500"
                : "bg-blue-500"
            }`}
            onClick={() => handleAnswerClick(index, option.isCorrect)}
            disabled={selectedAnswerIndex !== null}
          >
            {option.text}
          </button>
        ))}
      </div>
    </div>
  );
}

export default Question1;
