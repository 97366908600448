import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoadingScreen from "./layouts/Loading";
import HomePage from "./layouts/HomePage";
import PlatformHome from "./layouts/PlatformHome";
import BladeHome from "./layouts/BladeHome";
import PlatformQ1 from "./layouts/platform_qna/Question1";
import BladeQ1 from "./layouts/blade_qna/Question1";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <div className="h-screen flex justify-center align-middle app-background">
      <Router>
        {loading ? (
          <LoadingScreen />
        ) : (
          <Routes>
            <Route path="/" exact element={<HomePage />} />
            <Route path="/PlatformHome" exact element={<PlatformHome />} />
            <Route path="/BladeHome" exact element={<BladeHome />} />
            <Route path="/loading" exact element={<LoadingScreen />} />
            {/* E-Platform Question */}
            <Route path="/PlatformHome/question1" exact element={<PlatformQ1 />} />
            {/* Blade Battery Question */}
            <Route path="/BladeHome/question1" exact element={<BladeQ1 />} />
          </Routes>
        )}
      </Router>
    </div>
  );
}

export default App;
